// global navigator, URLSearchParams
import { settings } from 'Js/config/settings';
import { sriClient } from 'ReduxLoop/utils/apiConfig';
import {
  getDateOfBirthFromRegistrationNumber,
  validateRegistrationNumber,
  getDateOfBirthErrors,
} from 'Js/module/personUtils';
import { tryGoingToRegisterPage } from 'ReduxLoop/registerAndConfirmInvitation/register/registerActions';
import {
  setInvitations,
  fetchPositionAndOus,
  fetchInvitationCampusses,
  fetchInvitedUser,
  invitedUserFetched,
} from 'ReduxLoop/registerAndConfirmInvitation/inviteConfirmation/invitationActions';
import { createEmailAddress } from 'ReduxLoop/utils/personResourceFactory';
import { displayOuType } from 'ReduxLoop/appConfig';
import { initialiseRequestResponsibility } from 'ReduxLoop/requestResponsibility/requestResponsibilityActions';

const oauthClient = require('@kathondvla/sri-client/fetch-sri-client')({
  baseUrl: settings.oauth.oauthURL,
});

const capitalize = (string) => {
  const words = string.split(' ');
  words.forEach((word, index) => {
    words[index] = word
      .split('-')
      .map((s) => s.charAt(0).toUpperCase() + s.substr(1).toLowerCase())
      .join('-');
  });
  return words.join(' ');
};

navigator.sayswho = (function () {
  var ua = navigator.userAgent, //eslint-disable-line
    tem,
    M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
  if (/trident/i.test(M[1])) {
    tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
    return `IE ${tem[1] || ''}`;
  }
  if (M[1] === 'Chrome') {
    tem = ua.match(/\b(OPR|Edge)\/(\d+)/);
    if (tem !== null) {
      // return tem.slice(1).join(' ').replace('OPR', 'Opera');
      tem = tem.slice(1);
      return {
        browser: tem[0].replace('OPR', 'Opera'),
        version: tem[1],
      };
    }
  }
  M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];
  if ((tem = ua.match(/version\/(\d+)/i)) !== null) { //eslint-disable-line
    M.splice(1, 1, tem[1]);
  }
  return {
    browser: M[0],
    version: M[1],
  };
})();

const checkPrimaryEmail = async (person, emailAddress) => {
  if (!person.emailAddresses.primary) {
    console.warn(
      `INVITED_USER_NO_EMAIL: ${person.username} has no e-mailaddress, so we add the one on which he was invited, we add the e-mailaddress on which he was invited: ${emailAddress}.`
    );
    const email = createEmailAddress(emailAddress, person);
    await sriClient.updateResouce(email);
  } else {
    const emailOccurences = await sriClient.getAll('/contactdetails', {
      expand: 'NONE',
      values: person.emailAddresses.primary.$$expanded.value,
    });
    if (emailOccurences.length > 1) {
      console.warn(
        `INVITED_USER_NO_UNIQUE_EMAIL: ${person.username}-${person.key}, invited on ${emailAddress} his primary e-mailaddress, ${person.emailAddresses.primary.$$expanded.value} is not unique.`
      );
    }
  }
};

export class Aanmelden {
  constructor($scope, $state, $stateParams, $ngRedux, LogRocket) {
    'ngInject';

    this.userIcon = require('Img/user.svg');
    this.rightArrow = require('Img/arrow-right.svg');
    this.editIcon = require('Img/edit.svg');
    this.$scope = $scope;
    this.$state = $state;
    this.$ngRedux = $ngRedux;
    this.$stateParams = $stateParams;
    this.username = $stateParams.username;
    this.mijn1 = settings.applications.mijn1;
    this.askPasswordReset = `/#!/vraag-nieuw-wachtwoord?redirect_url=${encodeURIComponent(window.location.href)}`;
    this.dateOfBirthErrors = [];
    this.validateRegistrationNumber = validateRegistrationNumber;
    this.LogRocket = LogRocket;

    this.unsubscribe = this.$ngRedux.connect((state) => ({
      isFetchingOu: state.requestResponsibility.ou || state.requestResponsibility.loading,
      registrationNumber: state.register.registrationNumber,
      firstName: state.register.firstName,
      lastName: state.register.lastName,
      organisationalUnit: state.invite.organisationalUnits[0],
      dateOfBirth: state.register.dateOfBirth,
      invitationKey: state.invite.invitationKey,
      invitationMap: state.invite.invitationMap,
    }))(this);
  }

  $onDestroy() {
    this.unsubscribe();
  }

  $onInit() {
    if (this.$stateParams.key) {
      this.subTitle =
        'Heb je reeds een account bij Katholiek Onderwijs Vlaanderen? Meld je dan aan om de uitnodiging te aanvaarden.';
      this.$ngRedux.dispatch(
        setInvitations(
          'INVITATION',
          this.$stateParams.key,
          this.$stateParams.email,
          this.$stateParams.start
        )
      );
      this.$ngRedux.dispatch(
        fetchPositionAndOus(this.$stateParams.functie, [this.$stateParams.ou])
      );
    } else if (this.$stateParams.ous) {
      this.subTitle =
        'Heb je reeds een account bij Katholiek Onderwijs Vlaanderen? Meld je dan aan om de uitnodiging te aanvaarden.';
      const ous = JSON.parse(this.$stateParams.ous);
      this.$ngRedux.dispatch(
        setInvitations(
          'MULTIPLE_INVITATIONS',
          ous,
          this.$stateParams.email,
          this.$stateParams.start
        )
      );
      this.$ngRedux
        .dispatch(
          fetchPositionAndOus(
            this.$stateParams.functie,
            ous.map((obj) => obj.ou)
          )
        )
        .then(() => this.$ngRedux.dispatch(fetchInvitationCampusses()));
    } else {
      this.subTitle =
        'Heb je reeds een account bij Katholiek Onderwijs Vlaanderen? Meld je dan aan om een rol aan te vragen.';
      if (this.$stateParams.ou && !this.isFetchingOu) {
        this.$ngRedux.dispatch(initialiseRequestResponsibility(this.$stateParams));
      }
    }
    // sriClient.getList('/persons', { omit: 'dateOfBirth,registrationNumber,$$email' });
    // oauthClient.get('', undefined, {baseUrl: settings.oauth.logOut, credentials: "include"});
  }

  usernameChanged(ev, username) {
    this.username = username;
  }

  async logIn() {
    if (this.loading) {
      return;
    }
    this.loginAttempted = true;
    if (!this.password) {
      this.loginFailed = 'Vul jouw wachtwoord in om aan te melden.';
    }
    if (!this.loginForm.valid || !this.password) {
      return;
    }
    this.loginFailed = null;
    let authenticateWorked = false;
    try {
      /* const authorization = window.btoa(this.username + ':' + this.password);
      const resp = await oauthClient.get('/check', undefined, {headers: {Authorization: 'Basic ' + authorization}}); */

      this.LogRocket.identify(this.username, {
        name: this.username,
      });

      this.loading = true;
      // log out
      await oauthClient.get('', undefined, {
        baseUrl: settings.oauth.logOut,
        credentials: 'include',
      });
      // send /authenticate call to bootstrap oauth (send client id, response type)
      const data = {
        client_id: settings.oauth.clientID,
        redirect_uri: settings.oauth.redirectUri,
        response_type: 'manual',
        scope: settings.oauth.scope,
      };
      // console.log('authenticate url', settings.oauth.authenticate);

      await oauthClient.get('', data, {
        baseUrl: settings.oauth.authenticate,
        credentials: 'include',
        logging: 'get',
      });
      const formData = new URLSearchParams();
      formData.append('username', this.username);
      formData.append('password', this.password);
      formData.append('response_type', 'manual');

      // console.warn('Form data: ', formData.toString());
      authenticateWorked = true;

      const resp = await oauthClient.post('/login', formData.toString(), {
        baseUrl: settings.oauth.oauthURL,
        raw: true,
        mode: 'cors',
        credentials: 'include',
        logging: 'post',
        fullResponse: true,
        headers: { 'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8' },
      });

      if (
        resp.headers['content-type'] &&
        resp.headers['content-type'].match(/application\/json/g)
      ) {
        // since SSO flow resp.body.passedUserDataValidation will always be true for response_type=manual
        if (resp.body.passedUserDataValidation) {
          const person = await sriClient.get(`/persons/${resp.body.uuid}`, {
            expandEmailAddresses: 'FULL',
          });
          await checkPrimaryEmail(person, this.$stateParams.email);
          if (person.dateOfBirth) {
            this.$ngRedux.dispatch(invitedUserFetched(resp.body));
            if (this.invitationKey) {
              this.$state.go('acceptInvitation');
            } else if (this.invitationMap) {
              this.$state.go('selectOuForInit');
            } else {
              // this.loginFailed = 'De bevestingslink is onjuist of onvolledig';
              this.$state.go('requestResponsibility', this.$stateParams);
            }
          } else {
            console.warn(
              `NO_DATE_OF_BIRTH: ${this.username} has no date of birth. He/She first needs to fill in before he/she can register on the school.`
            );
            if (this.organisationalUnit) {
              const displayType = displayOuType(this.organisationalUnit.type);
              this.loginFailed = `Je gegevens zijn onvolledig. Om jezelf te koppelen aan een ${displayType} vragen we om minstens een stamboeknummer (of geboortedatum) in te vullen. Dit vragen we om dubbels te kunnen detecteren.  <a href="${
                this.mijn1
              }/#/me?BACK_URL=${encodeURIComponent(
                `${window.location.href}&username=${this.username}`
              )}&complete_profile=true">Ga naar je profiel om je stamboeknummer of geboortedatum in te vullen.</a>`;
            } else {
              this.loginFailed = `Je gegevens zijn onvolledig. Om jezelf te koppelen aan een instelling vragen we om minstens een stamboeknummer (of geboortedatum) in te vullen. Dit vragen we om dubbels te kunnen detecteren.  <a href="${
                this.mijn1
              }/#/me?BACK_URL=${encodeURIComponent(
                `${window.location.href}&username=${this.username}`
              )}&complete_profile=true">Ga naar je profiel om je stamboeknummer of geboortedatum in te vullen.</a>`;
            }
          }
        } else {
          const userAgent = navigator.sayswho;
          console.warn(
            `USER_DATA_VALIDATION: ${this.username} is required to do user data validation on ${userAgent.browser} ${userAgent.version}`
          );
          this.loginFailed = `Je gegevens zijn onvolledig (of jouw e-mailadres is niet uniek), <a href="${
            this.mijn1
          }/#/me?BACK_URL=${encodeURIComponent(
            `${window.location.href}&username=${this.username}`
          )}&complete_profile=true">Ga naar je profiel om je gegevens te vervolledigen</a>`;
        }
      } else {
        // deprecated when login still redirected
        this.$ngRedux.dispatch(fetchInvitedUser());
        if (this.invitationKey) {
          this.$state.go('acceptInvitation');
        } else if (this.invitationMap) {
          this.$state.go('selectOuForInit');
        } else {
          // this.loginFailed = 'De bevestingslink is onjuist of onvolledig';
          this.$state.go('requestResponsibility', this.$stateParams);
        }
      }

      /* we like sriClient response handling
      await fetch(settings.oauth.oauthURL + '/login', {
        method: 'POST',
        cache: 'no-cache',
        credentials: "include",
        redirect: "follow",
        headers: {'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'},
        body: formData
      }); */
    } catch (err) {
      if (err.response) {
        console.error('error', err);
        if (err.response.redirected) {
          // if(err.status === 409) {err.response.redirected
          console.warn(
            `USER_DATA_VALIDATION: ${this.username} is required to do user data validation`
          );
          this.loginFailed = `Je gegevens zijn onvolledig (of jouw e-mailadres is niet uniek), <a href="${
            this.mijn1
          }/#/me?BACK_URL=${encodeURIComponent(
            `${window.location.href}&username=${this.username}`
          )}&complete_profile=true">Ga naar je profiel om je gegevens te vervolledigen</a>`;
        } else if (err.status === 401) {
          console.warn(`WRONG_USERNAME_PASSWORD: ${this.username} has entered a wrong password`);
          this.loginFailed = `De gebruikersnaam of het wachtwoord is fout. Probeer het opnieuw of <a href="${this.askPasswordReset}?username=${this.username}" target="_blank">kies een nieuw wachtwoord.</a>`;
        } else if (err.status === 400) {
          const userAgent = navigator.sayswho;
          console.warn(
            `LOGIN_BAD_REQUEST: POST /login returned 400!!!!! for ${this.username} on ${userAgent.browser} ${userAgent.version}`
          );
          if (userAgent.browser === 'Chrome') {
            this.loginFailed =
              'Jouw browser blokeert indirecte cookies. Ga naar chrome://settings/content/cookies om deze optie uit te zetten of probeer het in Mozilla Firefox.';
          } else {
            this.loginFailed =
              'Jouw browser blokeert indirecte cookies. Probeer het in Goolge Chrome of Mozilla Firefox.';
          }
        } else {
          console.warn(
            `UNKNOWN_ERROR_FROM_OAUTH: POST /login returned a none 400 or 401 error for ${this.username}`
          );
          this.loginFailed =
            'Er deed zich een onbekende fout voor. Probeer het eventueel opnieuw met een recente versie van Chrome of Firefox';
        }
      } else {
        const userAgent = navigator.sayswho;
        console.error(
          `LOGIN_ERROR: ${this.username} got an unexpected exception on ${userAgent.browser} ${userAgent.version} | authenticate worked: ${authenticateWorked}`,
          err
        );
        if (userAgent.browser === 'Chrome') {
          this.loginFailed =
            'Er deed zich een onbekende fout voor. Probeer het eventueel opnieuw in een incognito venster of in Mozilla Firefox';
        } else if (userAgent.browser === 'Firefox') {
          this.loginFailed =
            'Er deed zich een onbekende fout voor. Probeer het eventueel opnieuw in een incognito venster of in Google Chrome';
        } else {
          this.loginFailed =
            'Er deed zich een onbekende fout voor. Probeer het eventueel opnieuw met een recente versie van Google Chrome of Mozilla Firefox';
        }
      }
      this.$scope.$apply();
    } finally {
      this.loading = false;
      this.$scope.$apply();
    }
  }

  registrationNumberChanged(e, rn) {
    this.registrationNumber = rn;
    if (this.registrationNumber && this.registerForm.formGroups.stamboeknummer.valid) {
      this.dateOfBirth = getDateOfBirthFromRegistrationNumber(this.registrationNumber);
    }
    this.manageDateOfBirthErrors();
  }

  firstNameChanged(e, firstName) {
    this.firstName = firstName;
  }

  lastNameChanged(e, lastName) {
    this.lastName = lastName;
  }

  dateOfBirthChanged(e, dateOfBirth) {
    this.dateOfBirth = dateOfBirth;
    this.manageDateOfBirthErrors();
  }

  manageDateOfBirthErrors() {
    this.dateOfBirthErrors = getDateOfBirthErrors(
      this.dateOfBirth,
      this.registrationNumber,
      this.registerForm.formGroups.stamboeknummer.valid
    );
  }

  register() {
    this.LogRocket.identify(`${this.firstName}.${this.lastName}.registering`, {
      name: `${this.firstName}.${this.lastName}.registering`,
    });

    if (this.invitationMap) {
      this.registerFailed = `Je hebt al een account bij Katholiek Onderwijs Vlaanderen. Meld je aan op de linkerhelft van dit scherm of <a href="${this.askPasswordReset}" target="_blank">klik hier om een nieuw wachtwoord aan te vragen.</a>`;
      return;
    }
    this.registerAttempted = true;
    if (!this.registerForm.valid || this.dateOfBirthErrors.length > 0) {
      return;
    }
    /* this.registerFailed = 'We zijn nog volop bezig met dit onderdeel af te werken. Wacht tot morgen om je te registreren.';
    return; */
    this.registerLoading = true;
    this.$ngRedux
      .dispatch(
        tryGoingToRegisterPage({
          registrationNumber: this.registrationNumber,
          firstName: capitalize(this.firstName),
          lastName: capitalize(this.lastName),
          dateOfBirth: this.dateOfBirth,
        })
      )
      .finally(() => {
        this.registerLoading = false;
      });
  }
}
